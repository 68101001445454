export function debounceAsync(callback, delay) {
    let timer = null;

    return (...args) => {
        if (timer) {
            clearTimeout(timer);
        }

        return new Promise((resolve, reject) => {
            timer = setTimeout(async () => {
                try {
                    const result = await callback(...args);
                    resolve(result);
                } catch (error) {
                    reject(error);
                } finally {
                    timer = null;
                }
            }, delay);
        });
    };
}

export default { debounceAsync };
